export function appType(): AppType {
    if (cookieWithName("appType") === undefined) {
        return AppType.NO_APP;
    }
    const appType = cookieWithName("appType").split("=")[1]
    if (appType) {
        if (appType === 'ios') {
            return AppType.IOS
        } else if (appType === 'android') {
            return AppType.ANDROID
        }
    }
    return AppType.NO_APP;
}

export enum AppType {
    IOS = "ios",
    ANDROID = "android",
    NO_APP = "",
}

function splitCookies() {
    if (!document.cookie) return []
    return document.cookie.split("; ")
}

function cookieWithName(name: string) {
    return splitCookies().filter(cookie => cookie.indexOf(name) === 0)[0]
}

export function runningInApp() {
    return splitCookies().filter(cookie => cookie === "app=true").length === 1
}
