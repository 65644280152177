import type {AsyncNamespace, OttoNexus} from "@otto-ec/global-resources/nexus";
import type {Sharing} from "./sharing"
import type {Global} from "./global";
import type {PushNotifications} from "./pushnotifications";
import type {CookieBanner} from "./cookiebanner";
import type {HapticFeedback} from "./hapticfeedback";

declare global {
    interface NexusInterfaces {
        /**
 *
 */
        apps: {
            cookieBanner: AsyncNamespace<CookieBanner>
            global: AsyncNamespace<Global>,
            hapticFeedback: AsyncNamespace<HapticFeedback>,
            pushNotifications: AsyncNamespace<PushNotifications>,
            sharing: AsyncNamespace<Sharing>,
        }
    }
}

export type apps = OttoNexus["apps"]

export const apps: OttoNexus["apps"] = otto.apps